import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import ListingCardNew from "../../components/Card/listingcardnew"
import SEOHeader from "../../components/seo-header"
import { graphql, Link } from "gatsby"
import BlogCardCategory from "../../components/blog/blog-card-category"
const RencentlyAddedCoworkingSpacesLocalityAll = props => {
  const { city } = props.pageContext
  const { cityslug } = props.pageContext
  const { slug } = props.pageContext
  const [filterCity, setFilterCity] = useState("All")
  const filter = {
    category: "Coworking",
    keyword: city,
  }

  function selectCity(cityname) {
    setFilterCity(cityname)
  }

  const lists = props.data.allListings.edges
  const locality = props.data.allCities.edges
  return (
    <div>
      <SEOHeader
        title={"Recently Added of coworking space | GoFloaters"}
        description={
          "Easiest way to find & book on-demand coworking spaces. Find workspaces that can help you rediscover your work mojo at coworking space near you."
        }
        socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
      ></SEOHeader>
      <Layout>
        <div className="container">
          <br></br>
          <h1>Recently Added Coworking spaces</h1>
          <br></br>
          <div className="filterbar" style={{ marginTop: 15 }}>
            <ul className="SearchListingFilter scrollmenu">
              <li>
                <Link to="/recently-added/coworking-spaces" className="active">
                  Coworking Spaces
                </Link>
              </li>
              <li>
                <Link to="/recently-added/meeting-spaces">Meeting Spaces</Link>
              </li>
              <li>
                <Link to="/recently-added/office-spaces">Office Spaces</Link>
              </li>
            </ul>
          </div>
          <div class="popularscrollmenu-s">
            <a
              onClick={() => selectCity("All")}
              className={
                filterCity === "All"
                  ? "popularlocalityLink active"
                  : "popularlocalityLink"
              }
            >
              All
            </a>
            {locality.map(local => {
              const localitynew = lists.filter(
                list =>
                  list.node.spaceCity === local.node.city &&
                  list.node.dayPassAvailable === true
              )
              if (localitynew.length > 0) {
                return (
                  <a
                    onClick={() => selectCity(local.node.city)}
                    className={
                      filterCity === local.node.city
                        ? "popularlocalityLink centerAligner active"
                        : "popularlocalityLink centerAligner"
                    }
                  >
                    {local.node.displayCity}
                    {"  "}
                    <div className="countSlip centerAligner">
                      {localitynew.length}
                    </div>
                  </a>
                )
              }
            })}
          </div>
          <div className="row">
            {lists.map((list, i) => {
              const listData = list.node
              if (filterCity === "All") {
                return (
                  <ListingCardNew
                    key={listData.spaceId}
                    spacetype={listData.spaceType}
                    listingImg={listData.spaceImage}
                    title={listData.spaceTitle}
                    gftitle={listData.spaceGFName}
                    spaceDesc={listData.spaceDesc}
                    spaceAddress={listData.spaceAddress}
                    priceHr={listData.priceHr}
                    priceDay={listData.priceDay}
                    priceMonth={listData.priceMonth}
                    monthPassAvailable={listData.monthPassAvailable}
                    dayPassAvailable={listData.dayPassAvailable}
                    hourPassAvailable={listData.hourPassAvailable}
                    spaceId={listData.spaceId}
                    officeSpaceType={listData.officeSpaceType}
                    spaceDisplayName={listData.spaceDisplayName}
                    OriginalName={listData.OriginalName}
                    Facility={listData.Facility}
                    Slug={"/coworking-space/" + listData.slug}
                    hasCovidSafeBadge={listData.hasCovidSafeBadge}
                    online={listData.online}
                    Rating={listData.Rating}
                  ></ListingCardNew>
                )
              }
              if (listData.spaceCity === filterCity) {
                return (
                  <ListingCardNew
                    key={listData.spaceId}
                    spacetype={listData.spaceType}
                    listingImg={listData.spaceImage}
                    title={listData.spaceTitle}
                    gftitle={listData.spaceGFName}
                    spaceDesc={listData.spaceDesc}
                    spaceAddress={listData.spaceAddress}
                    priceHr={listData.priceHr}
                    priceDay={listData.priceDay}
                    priceMonth={listData.priceMonth}
                    monthPassAvailable={listData.monthPassAvailable}
                    dayPassAvailable={listData.dayPassAvailable}
                    hourPassAvailable={listData.hourPassAvailable}
                    spaceId={listData.spaceId}
                    officeSpaceType={listData.officeSpaceType}
                    spaceDisplayName={listData.spaceDisplayName}
                    OriginalName={listData.OriginalName}
                    Facility={listData.Facility}
                    Slug={"/coworking-space/" + listData.slug}
                    hasCovidSafeBadge={listData.hasCovidSafeBadge}
                    online={listData.online}
                    Rating={listData.Rating}
                  ></ListingCardNew>
                )
              }
            })}
          </div>
        </div>{" "}
        <br></br>
        <br></br> <br></br>{" "}
      </Layout>
    </div>
  )
}
export default RencentlyAddedCoworkingSpacesLocalityAll
export const query = graphql`
  query RecentlyAddedCoworkingSpacesLocalityAll {
    allListings(
      filter: {
        spaceType: { eq: "Shared Workspace" }
        dayPassAvailable: { eq: true }
        online: { eq: true }
      }
      sort: { fields: dateAdded, order: DESC }
      limit: 50
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceDesc
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
        }
      }
    }
    allCities(sort: { fields: parent___id, order: ASC }) {
      edges {
        node {
          city
          cityPriority
          displayCity
          slug
          id
        }
      }
    }
  }
`
